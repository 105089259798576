.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App header {
  background-color: #222222;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App section.field {
  background-color: #28342c;
  height: 90vh;
  width: 100vw;
  position: relative;
}

.App section.field img {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 85%;
}

.App section.field .bench {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15%;
  background: #222
}

.App section.field .players {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.App section.field .players code {
  color: white;
}

.player {
  display: inline-block;
  background: #222;
  padding: .25em;
  border-radius: .25em;
  border: 1px solid white;
  padding: 0.5em;
}

div.current-team {
  font-size: 2em;
}


button, select, input {
  font-family: monospace;
  color: white;
  background: #222;
}
select {
  font-size: 2rem;
}

button, input {
  font-size: 1.25rem;
  padding: .25em .5em;
}

.menu {
  position: fixed;
  z-index: 20;
  background-color: #222222;
  border: 1px solid white;
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 20px;
  padding: 0.5em;
}

.menu select {
  font-size: 1.5rem;
}
.menu header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.menu header div {
  display: flex;
}

.menu section {
  border: 1px solid white;
  margin-top: 1rem;
  padding: 0.5rem;
}

.menu section > div {
  font-family: monospace;
  color: white;
  font-size: 1.5rem;
}

.menu section > div {
  text-align: center;
  margin-bottom: .5rem;
}

.menu section > div > textarea {
  width: calc(100% - 2rem);
  background-color: #222;
  color: white;
  padding: 0.5rem;
  border: 1px solid white;
  height: 25rem;
}

.menu section > div:first-of-type {
  text-align: left;
}

.menu section > div:last-of-type {
  text-align: right;
  margin-bottom: 0;
}

